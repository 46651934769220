import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Pagination,
  CardActions,
  CircularProgress,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateIcon from "@mui/icons-material/Create";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import moment from "moment";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import myToast from "../../components/myToast";
import { useLanguage } from "../../components/languageProvider";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const size = 6;
  const texts = languages[currentLanguage];
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userDeleteModal, setUserDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [params, setParams] = useState("");
  const [activeTab, setActiveTab] = useState("1");

  const navigate = useNavigate();

  const getAllUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/user/getAllUserWithBgPage?page=${page}&size=${size}&param=${params}&sort=userRegistrationDate,desc`
      );
      const data = await response.json();
      setTotalPages(data?.totalPages);
      setUsers(data?.content);
    } catch (error) {
      myToast({
        status: "error",
        message: "An error occurred while fetching data",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllUser();
  }, [page, params]);

  const TABLE_HEAD = [
    "",
    texts.userTableTitle.name + " " + texts.userTableTitle.surname,
    texts.userTableTitle.companyNumber,
    texts.userTableTitle.userName,
    texts.userTableTitle.phone,
    texts.userTableTitle.userActive,
    texts.userTableTitle.freeTrial,
    texts.userTableTitle.userRegistered,
    "",
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleOpenDeleteUserModal = (user) => {
    setSelectedUser(user);
    setUserDeleteModal(true);
  };

  const handleShowImages = (user) => {
    setSelectedUser(user);
    setShowImagesModal(true);
  };

  const handleDeleteUser = async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/user/deleteUser?userId=${selectedUser?.userId}`,
        {
          method: "DELETE",
        }
      );
      if (response.status === 200) {
        myToast({
          status: "success",
          message: texts.saveStatus.deleteUserSuccess,
        });
        getAllUser();
      } else {
        myToast({
          status: "error",
          message: texts.saveStatus.deleteUserError,
        });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.userDeleteError });
    }
    setDeleteLoading(false);
    setUserDeleteModal(false);
  };
  const handleUpdateUserStatus = async (user, status) => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/user/changeActiveStatus?userId=${user.userId}&status=${status}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (data) {
        toast.success(texts.success);
        getAllUser();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Card variant="outlined" sx={{ width: "100%", mx: 4 }}>
        <CardHeader title={texts.users} />
        <CardContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <InputBase
              placeholder={texts.search}
              size="small"
              onChange={(e) => setParams(e.target.value)}
              sx={{
                border: "1px solid #cfd8dc",
                borderRadius: "4px",
                padding: "8px",
                width: "80%",
                marginRight: "16px",
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                navigate("/users/add-user");
              }}
              startIcon={<AddCircleOutlineIcon />}
            >
              {texts.userTableTitle.addUser}
            </Button>
          </div>
          {loading ? (
            <div className="flex items-center justify-center">
              <CircularProgress size={80} />
            </div>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((head) => (
                      <TableCell key={head} align="left">
                        <Typography variant="body2" fontWeight="medium">
                          {head}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((item, index) => {
                    // Kullanıcı kaydı bugünün tarihindeyse kontrol et
                    const isNewUser = moment(item.createdAt).isSame(
                      moment(),
                      "day"
                    );

                    return (
                      <TableRow
                        key={item.userId}
                        sx={{
                          backgroundColor: isNewUser ? "#e0f7fa" : "inherit", // Bugünse açık mavi, değilse varsayılan
                        }}
                      >
                        <TableCell>
                          <Avatar
                            src={
                              item?.userImagePath
                                ? `${API_URL}:${API_PORT}/${item.userImagePath}`
                                : "https://www.svgrepo.com/show/474987/shop.svg"
                            }
                            alt={item.userName}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {item?.userName + " " + item?.userSurname} <br />
                            {item?.userFirmName}
                          </Typography>
                        </TableCell>
                        <TableCell>{item?.customerNumber}</TableCell>
                        <TableCell>{item.userUsername}</TableCell>
                        <TableCell>{item.userPhone}</TableCell>
                        <TableCell>
                          <select
                            className={
                              "border border-blue-gray-200 rounded-md p-1" +
                              (item.userActive
                                ? " text-green-500"
                                : " text-red-500")
                            }
                            name="status"
                            id="status"
                            value={item.userActive ? "true" : "false"}
                            onChange={(e) => {
                              handleUpdateUserStatus(item, e.target.value);
                            }}
                          >
                            <option value="true" className="text-green-500">
                              {texts.yes}
                            </option>
                            <option value="false" className="text-orange-500">
                              {texts.no}
                            </option>
                          </select>
                        </TableCell>
                        <TableCell>
                          {item.userTrial ? (
                            <Typography color="green">{texts.yes}</Typography>
                          ) : (
                            <Typography color="red">{texts.no}</Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(item.userRegistrationDate).format(
                            "DD/MM/YYYY"
                          )}
                        </TableCell>
                        <TableCell>
                          <Tooltip title={texts.showImages}>
                            <IconButton onClick={() => handleShowImages(item)}>
                              <RemoveRedEyeIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={texts.userTableTitle.editUser}>
                            <IconButton
                              onClick={() => {
                                navigate(`/users/edit-user/${item.userId}`);
                              }}
                            >
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={texts.userTableTitle.deleteUser}>
                            <IconButton
                              onClick={() => handleOpenDeleteUserModal(item)}
                            >
                              <DeleteForeverIcon color="error" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Pagination
            count={totalPages}
            page={page + 1}
            onChange={handlePageChange}
          />
        </CardActions>
      </Card>

      <Dialog open={userDeleteModal} onClose={() => setUserDeleteModal(false)}>
        <DialogTitle>{selectedUser?.userName}</DialogTitle>
        <DialogContent>
          <Typography>{texts.saveStatus.deleteUserMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUserDeleteModal(false)} color="primary">
            {texts.no}
          </Button>
          <Button
            onClick={handleDeleteUser}
            color="error"
            disabled={deleteLoading}
          >
            {deleteLoading ? "Deleting..." : texts.yes}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showImagesModal}
        onClose={() => setShowImagesModal(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>{texts.userModalTable.userDetails}</DialogTitle>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
        >
          <Tab label={texts.packagesTableTitle.packageInformation} value="1" />
          <Tab label={texts.Images} value="2" />
        </Tabs>

        <DialogContent>
          <Box hidden={activeTab !== "1"}>
            <div className="overflow-y-auto max-h-96 p-4 bg-gray-100 rounded-md shadow-lg font-[Inter]">
              <h2 className="text-xl font-bold mb-4">
                {texts.userModalTable.userInformation}
              </h2>
              <div className="space-y-2">
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.id} :</strong>{" "}
                  {selectedUser?.userId}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userTableTitle.companyNumber} :</strong>{" "}
                  {selectedUser?.customerNumber}
                </p>
                <p className="text-gray-700">
                  <strong> {texts.userModalTable.name} :</strong>{" "}
                  {selectedUser?.userName}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.surname} :</strong>{" "}
                  {selectedUser?.userSurname}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.userName} :</strong>{" "}
                  {selectedUser?.userUsername}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.password} :</strong>{" "}
                  {selectedUser?.userPassword}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.role} :</strong>{" "}
                  {selectedUser?.userRole}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.registerDate} :</strong>{" "}
                  {moment(selectedUser?.userRegistrationDate).format(
                    "DD/MM/YYYY"
                  )}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.freeTrial} :</strong>
                  {selectedUser?.userTrial
                    ? texts.userModalTable.freeTrialYes
                    : texts.userModalTable.freeTrialNo}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.email} :</strong>{" "}
                  {selectedUser?.userEmail}
                </p>
                <p className="text-gray-700">
                  <strong> {texts.userModalTable.phone} :</strong>{" "}
                  {selectedUser?.userPhone}
                </p>
                <p className="text-gray-700">
                  <strong> {texts.userModalTable.companyName} :</strong>{" "}
                  {selectedUser?.userFirmName}
                </p>
                <p className="text-gray-700">
                  <strong> {texts.userModalTable.userTaxNumber} :</strong>{" "}
                  {selectedUser?.userTaxNumber}
                </p>
              </div>
              {selectedUser?.bankAccount?.paypalNumber ? (
                // Eğer PayPal numarası varsa
                <>
                  <h3 className="text-lg font-semibold mt-6 mb-4">
                    {texts.userModalTable.userPaypalInfo}
                  </h3>
                  <div className="space-y-2">
                    <p className="text-gray-700">
                      <strong>
                        {texts.userModalTable.userBankAccountOwner} :
                      </strong>
                      {selectedUser?.bankAccount?.bankAccountUserName +
                        " " +
                        selectedUser?.bankAccount?.bankAccountUserSurname}
                    </p>
                    <p className="text-gray-700">
                      <strong>{texts.userModalTable.userPaypalEmail} :</strong>
                      {selectedUser?.bankAccount?.paypalNumber}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <h3 className="text-lg font-semibold mt-6 mb-4">
                    {texts.userModalTable.userBankInfo}
                  </h3>
                  <div className="space-y-2">
                    <p className="text-gray-700">
                      <strong>
                        {texts.userModalTable.userBankAccountOwner} :
                      </strong>
                      {selectedUser?.bankAccount?.bankAccountUserName +
                        " " +
                        selectedUser?.bankAccount?.bankAccountUserSurname}
                    </p>
                    <p className="text-gray-700">
                      <strong>{texts.userModalTable.userBankName} :</strong>
                      {selectedUser?.bankAccount?.bankName}
                    </p>
                    <p className="text-gray-700">
                      <strong>{texts.userModalTable.userIBAN} :</strong>
                      {selectedUser?.bankAccount?.iban}
                    </p>
                  </div>
                </>
              )}

              <h3 className="text-lg font-semibold mt-6 mb-4">
                {texts.userModalTable.dealerInfo}
              </h3>
              <div className="space-y-2">
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.dealerName} :</strong>{" "}
                  {selectedUser?.dealer?.dealerName}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.dealerAddress} :</strong>{" "}
                  {selectedUser?.dealer?.dealerAddress}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.dealerPhone} :</strong>{" "}
                  {selectedUser?.dealer?.dealerPhone}
                </p>
                <p className="text-gray-700">
                  <strong>{texts.userModalTable.dealerPostalCode} :</strong>{" "}
                  {selectedUser?.dealer?.dealerPostalCode}
                </p>
              </div>
            </div>
          </Box>
          <Box hidden={activeTab !== "2"}>
            {/* Buraya "Images" sekmesine ait içerikler eklenmeli */}
            <div className="flex flex-wrap overflow-y-auto h-96 justify-start gap-4 w-full">
              {selectedUser?.selectedImages.map((image, index) => (
                <img
                  key={index}
                  src={`${API_URL}:${API_PORT}/${image.imageName}`}
                  alt={image.imageName}
                  className="h-2/3 object-cover"
                />
              ))}
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowImagesModal(false)} color="primary">
            {texts.close}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Index;
